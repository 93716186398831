import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Switch, Route } from "react-router-dom";
import { Translate, withLocalize } from "react-localize-redux";
import withSizes from "react-sizes";
import { MobileOnlyView, TabletView, BrowserView } from "react-device-detect";
import isLaptop from "../../../utils/isLaptopScreen";

import displayToastMessageForResponse from "../../../utils/displayToastMessageForResponse";

import Icon from "../../../components/Icon";
import Tabs from "../../../components/Tabs";
import Tab from "../../../components/Tabs/Tab";
import TabContent from "../../../components/Tabs/TabContent";

import ProductPlan from "./ProductPlan";
import Modal from "../../../components/Modal";
import PurchaseHosting from "../../../components/PurchaseHosting";

import {
  getServers,
  isFetchingServers
} from "../../Servers/reducers/serversReducer";
import { fetchServers } from "../../Servers/actions/serversActions";

import ContentLoader from "../../../components/Loaders/ContentLoader";
import withLoading from "../../../components/Loaders/WithLoading";

import { addProductToCart, fetchCart } from "../../Cart/actions/cartActions";
import { getCartId, getCart } from "../../Cart/reducers/cartReducer";

import {
  compileRoute,
  ROUTE_SHOP_SERVERS,
  ROUTE_CART,
  ROUTE_SHOP_SERVERS_PACKAGE
} from "../../../routes/routes";

import { isAuthenticated } from "../../Authentication/reducers/authReducer";

import {
  PRODUCT_DEDICATED_SERVER,
  PRODUCT_CLOUD_SERVER
} from "../../Cart/Products/ProductTypes.js";

const StyledServersWrapper = styled.div`
  margin-left: -30px;
  margin-right: -30px;
`;

const StyledServersHeader = styled.div`
  background-color: ${theme.neutralBase};
  padding-top: 80px;
  padding-bottom: 80px;
`;

const StyledHeaderTitle = styled.h3`
  font-size: 30px;
  font-weight: 600;
  color: ${theme.white};
  text-transform: uppercase;
`;

const StyledSellingPoint = styled.span`
  color: ${theme.white};
  font-size: 16px;
  line-height: 1.38;
  display: block;
  margin-bottom: 7px;

  & > .icon {
    color: ${theme.greenBase};
    margin-right: 10px;
  }
`;

const StyledExtraSellingPoint = styled.span`
  color: ${theme.white};
  font-size: 16px;
  line-height: 1.38;
  font-weight: 600;
  margin-top: 20px;
`;

const StyledServersContent = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
`;

const ServersContentWithLoading = withLoading(
  StyledServersContent,
  ContentLoader
);

class Servers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedServer: null,
      submitting: false,
      submittingId: 0,
      currency: null
    };
  }

  componentDidMount() {
    if (!this.props.servers.length) {
      this.props.fetchServers().then(() => this.redirectToFirstTab());
    } else {
      this.redirectToFirstTab();
    }
  }

  redirectToFirstTab = () => {
    if (
      this.props.servers &&
      this.props.servers.length > 0 &&
      this.props.location.pathname === ROUTE_SHOP_SERVERS
    ) {
      this.props.history.push(
        compileRoute(ROUTE_SHOP_SERVERS_PACKAGE)({
          name: this.props.servers[0].name.replace(/\s+/g, "-").toLowerCase()
        })
      );
    }
  };

  onAddToCart = product => {
    this.setState({
      submitting: true,
      submittingId: product.id
    });
    
    let possiblePeriodNumericCodes = [0.1, 0.3, 0.5, 1, 2, 3];
    let firstDerivation = null;
    // Temporary solution, should be sync with api derivation numeric codes
    possiblePeriodNumericCodes.some(numericCode => {
      firstDerivation = product.pricing.buy.billing_cycle.derivations.find(
        d => d.numeric_code === numericCode
      );

      if (typeof(firstDerivation) !== 'undefined') {
        console.log(numericCode);
        return true;
      }
    });
    // END

    const dedicatedOrCloud =
      product.type === PRODUCT_DEDICATED_SERVER
        ? {
            dedicated_server_id: product.id,
            config: { type: "buy", period: firstDerivation.numeric_code }, //0.1
            type: PRODUCT_DEDICATED_SERVER,
            quantity: 1,
            domain: ""
          }
        : {
            cloud_server_id: product.id,
            config: { type: "buy", period: firstDerivation.numeric_code }, //0.1
            type: PRODUCT_CLOUD_SERVER,
            quantity: 1,
            domain: ""
          };

    return this.props
      .addProductToCart(dedicatedOrCloud)
      .then(
        result => {
          displayToastMessageForResponse(
            this.props.translate("shop.shop"),
            result,
            ROUTE_CART,
            this.props.translate("shop.view-cart")
          );

          this.setState({
            submitting: false,
            submittingId: 0
          });
          return result;
        },
        error => {
          displayToastMessageForResponse(
            this.props.translate("shop.shop"),
            error
          );
          this.setState({
            submitting: false,
            submittingId: 0
          });
        }
      )
      .catch(errorResult => {
        displayToastMessageForResponse(
          this.props.translate("shop.shop"),
          errorResult
        );
        this.setState({
          submitting: false,
          submittingId: 0
        });
      });
  };

  calculateServersColSize = numberOfServerss => {
    const size = numberOfServerss > 1 ? Math.round(12 / numberOfServerss) : 4;

    return size > 4 ? size : 4;
  };

  render() {
    return (
      <Translate>
        {({ translate }) => {
          return (
            <StyledServersWrapper className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <StyledServersHeader className="row">
                  <div className="col-12 col-sm-8 offset-sm-2 col-md-8 offset-md-2">
                    <StyledHeaderTitle>
                      {translate("shop.servers.title")}
                    </StyledHeaderTitle>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12">
                        <StyledSellingPoint>
                          <Icon icon="input-checkbox" />{" "}
                          {translate("shop.servers.complete-flexibility")}
                        </StyledSellingPoint>
                        <StyledSellingPoint>
                          <Icon icon="input-checkbox" />{" "}
                          {translate("shop.servers.scalable-and-secure")}
                        </StyledSellingPoint>
                        <StyledSellingPoint>
                          <Icon icon="input-checkbox" />{" "}
                          {translate("shop.servers.own-operating-system")}
                        </StyledSellingPoint>
                        {/* <StyledExtraSellingPoint>
                          <strong>
                            {translate("shop.servers.free-domain")}
                          </strong>
                        </StyledExtraSellingPoint> */}
                      </div>
                    </div>
                  </div>
                </StyledServersHeader>
                <MobileOnlyView>
                  <StyledServersContent className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      {this.props.servers && this.props.servers.length > 0 && (
                        <Tabs
                          defaultTab={
                            ROUTE_SHOP_SERVERS +
                            "/" +
                            this.props.servers[0].name
                              .replace(/\s+/g, "-")
                              .toLowerCase()
                          }
                          renderTabs={(active, onSelect) =>
                            this.props.servers.map(tab => {
                              if (tab.name.toLowerCase() === "cloud") {
                                return null; 
                              }
                              
                              const tabName = tab.name
                                .replace(/\s+/g, "-")
                                .toLowerCase();
                              return (
                                <Tab
                                  key={`tab-id-${tab.id}`}
                                  label={tabName}
                                  active={active}
                                  linkTo={ROUTE_SHOP_SERVERS + "/" + tabName}
                                >
                                  {tab.name}
                                </Tab>
                              );
                            })
                          }
                          renderTabContent={active =>
                            this.props.servers.map(tab => {
                              if (tab.name.toLowerCase() === "cloud") {
                                return null; 
                              }
                              const tabName = tab.name
                                .replace(/\s+/g, "-")
                                .toLowerCase();

                              return (
                                <Switch key={`switch-tab-${tabName}`}>
                                  <Route
                                    path={ROUTE_SHOP_SERVERS + "/" + tabName}
                                    exact
                                    render={() => (
                                      <TabContent
                                        key={`tab-${tabName}`}
                                        label={tabName}
                                        active={true}
                                        isLoading={!!tab.servers}
                                      >
                                        <div className="row">
                                          {tab.servers &&
                                            tab.servers.length > 0 &&
                                            tab.servers.map(server => {
                                              if (
                                                server.pricing.buy.billing_cycle
                                                  .derivations.length === 0
                                              )
                                                return null;

                                              return (
                                                <div
                                                  key={`product-plan-${server.id}`}
                                                  className={`col-12 col-sm-12 col-md-12`}
                                                >
                                                  <ProductPlan
                                                    hosting={server}
                                                    onAddToCart={
                                                      this.onAddToCart
                                                    }
                                                    disabled={
                                                      this.state.submitting &&
                                                      this.state
                                                        .submittingId ===
                                                        server.id
                                                    }
                                                    submitting={
                                                      this.state.submitting &&
                                                      this.state
                                                        .submittingId ===
                                                        server.id
                                                    }
                                                  />
                                                </div>
                                              );
                                            })}
                                        </div>
                                      </TabContent>
                                    )}
                                  />
                                </Switch>
                              );
                            })
                          }
                        />
                      )}
                    </div>
                  </StyledServersContent>
                </MobileOnlyView>
                <TabletView>
                  <StyledServersContent className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      {this.props.servers && this.props.servers.length > 0 && (
                        <Tabs
                          defaultTab={
                            ROUTE_SHOP_SERVERS +
                            "/" +
                            this.props.servers[0].name
                              .replace(/\s+/g, "-")
                              .toLowerCase()
                          }
                          renderTabs={(active, onSelect) =>
                            this.props.servers.map(tab => {
                              if (tab.name.toLowerCase() === "cloud") {
                                return null; 
                              }
                              const tabName = tab.name
                                .replace(/\s+/g, "-")
                                .toLowerCase();
                              return (
                                <Tab
                                  key={`tab-id-${tab.id}`}
                                  label={tabName}
                                  active={active}
                                  linkTo={ROUTE_SHOP_SERVERS + "/" + tabName}
                                >
                                  {tab.name}
                                </Tab>
                              );
                            })
                          }
                          renderTabContent={active =>
                            this.props.servers.map(tab => {
                              if (tab.name.toLowerCase() === "cloud") {
                                return null; 
                              }
                              let colSize = this.calculateServersColSize(
                                tab.servers.length
                              );
                              const tabName = tab.name
                                .replace(/\s+/g, "-")
                                .toLowerCase();

                              return (
                                <Switch key={`switch-tab-${tabName}`}>
                                  <Route
                                    path={ROUTE_SHOP_SERVERS + "/" + tabName}
                                    exact
                                    render={() => (
                                      <TabContent
                                        key={`tab-${tabName}`}
                                        label={tabName}
                                        active={true}
                                      >
                                        <div className="row">
                                          {tab.servers &&
                                            tab.servers.length > 0 &&
                                            tab.servers.map(server => {
                                              if (
                                                server.pricing.buy.billing_cycle
                                                  .derivations.length === 0
                                              )
                                                return null;

                                              return (
                                                <div
                                                  key={`product-plan-${server.id}`}
                                                  className={`col-12 col-sm-${colSize} col-md-${colSize} `}
                                                >
                                                  <ProductPlan
                                                    hosting={server}
                                                    onAddToCart={
                                                      this.onAddToCart
                                                    }
                                                    disabled={
                                                      this.state.submitting &&
                                                      this.state
                                                        .submittingId ===
                                                        server.id
                                                    }
                                                    submitting={
                                                      this.state.submitting &&
                                                      this.state
                                                        .submittingId ===
                                                        server.id
                                                    }
                                                  />
                                                </div>
                                              );
                                            })}
                                        </div>
                                      </TabContent>
                                    )}
                                  />
                                </Switch>
                              );
                            })
                          }
                        />
                      )}
                    </div>
                  </StyledServersContent>
                </TabletView>
                <BrowserView>
                  {this.props.isLaptop && (
                    <ServersContentWithLoading
                      isLoading={this.props.isFetchingServers}
                      className="row"
                    >
                      <div className="col-12 col-sm-10 offset-sm-1 col-md-10 offset-md-1">
                        {this.props.servers && this.props.servers.length > 0 && (
                          <Tabs
                            defaultTab={
                              ROUTE_SHOP_SERVERS +
                              "/" +
                              this.props.servers[0].name
                                .replace(/\s+/g, "-")
                                .toLowerCase()
                            }
                            renderTabs={(active, onSelect) =>
                              this.props.servers.map(tab => {
                                if (tab.name.toLowerCase() === "cloud") {
                                  return null; 
                                }
                                const tabName = tab.name
                                  .replace(/\s+/g, "-")
                                  .toLowerCase();
                                return (
                                  <Tab
                                    key={`tab-id-${tab.id}`}
                                    label={tabName}
                                    onSelect={onSelect}
                                    active={active}
                                    linkTo={ROUTE_SHOP_SERVERS + "/" + tabName}
                                  >
                                    {tab.name}
                                  </Tab>
                                );
                              })
                            }
                            renderTabContent={active =>
                              this.props.servers.map(tab => {

                                let colSize = this.calculateServersColSize(
                                  tab.servers.length
                                );
                                const tabName = tab.name
                                  .replace(/\s+/g, "-")
                                  .toLowerCase();

                                return (
                                  <Switch key={`switch-tab-${tabName}`}>
                                    <Route
                                      path={ROUTE_SHOP_SERVERS + "/" + tabName}
                                      exact
                                      render={() => (
                                        <TabContent
                                          key={`tab-${tabName}`}
                                          label={tabName}
                                          active={true}
                                        >
                                          <div className="row">
                                            {tab.servers &&
                                              tab.servers.length > 0 &&
                                              tab.servers.map(server => {
                                                if (
                                                  server.pricing.buy
                                                    .billing_cycle.derivations
                                                    .length === 0
                                                )
                                                  return null;

                                                return (
                                                  <div
                                                    key={`product-plan-${server.id}`}
                                                    className={`col-12 col-sm-${colSize} col-md-${colSize} `}
                                                  >
                                                    <ProductPlan
                                                      hosting={server}
                                                      onAddToCart={
                                                        this.onAddToCart
                                                      }
                                                      disabled={
                                                        this.state.submitting &&
                                                        this.state
                                                          .submittingId ===
                                                          server.id
                                                      }
                                                      submitting={
                                                        this.state.submitting &&
                                                        this.state
                                                          .submittingId ===
                                                          server.id
                                                      }
                                                    />
                                                  </div>
                                                );
                                              })}
                                          </div>
                                        </TabContent>
                                      )}
                                    />
                                  </Switch>
                                );
                              })
                            }
                          />
                        )}
                      </div>
                    </ServersContentWithLoading>
                  )}
                  {!this.props.isLaptop && (
                    <ServersContentWithLoading
                      isLoading={this.props.isFetchingServers}
                      className="row"
                    >
                      <div className="col-12 col-sm-8 offset-sm-2 col-md-8 offset-md-2">
                        {this.props.servers && this.props.servers.length > 0 && (
                          <Tabs
                            defaultTab={
                              ROUTE_SHOP_SERVERS +
                              "/" +
                              this.props.servers[0].name
                                .replace(/\s+/g, "-")
                                .toLowerCase()
                            }
                            renderTabs={(active, onSelect) =>
                              this.props.servers.map(tab => {
                                if (tab.name.toLowerCase() === "cloud") {
                                  return null; 
                                }
                                const tabName = tab.name
                                  .replace(/\s+/g, "-")
                                  .toLowerCase();
                                return (
                                  <Tab
                                    key={`tab-id-${tab.id}`}
                                    label={tabName}
                                    onSelect={onSelect}
                                    active={active}
                                    linkTo={ROUTE_SHOP_SERVERS + "/" + tabName}
                                  >
                                    {tab.name}
                                  </Tab>
                                );
                              })
                            }
                            renderTabContent={active =>
                              this.props.servers.map(tab => {
                                let colSize = this.calculateServersColSize(
                                  tab.servers.length
                                );
                                const tabName = tab.name
                                  .replace(/\s+/g, "-")
                                  .toLowerCase();

                                return (
                                  <Switch key={`switch-tab-${tabName}`}>
                                    <Route
                                      path={ROUTE_SHOP_SERVERS + "/" + tabName}
                                      exact
                                      render={() => (
                                        <TabContent
                                          key={`tab-${tabName}`}
                                          label={tabName}
                                          active={true}
                                        >
                                          <div className="row">
                                            {tab.servers &&
                                              tab.servers.length > 0 &&
                                              tab.servers.map(server => {
                                                if (
                                                  server.pricing.buy
                                                    .billing_cycle.derivations
                                                    .length === 0
                                                )
                                                  return null;

                                                return (
                                                  <div
                                                    key={`product-plan-${server.id}`}
                                                    className={`col-12 col-sm-${colSize} col-md-${colSize} `}
                                                  >
                                                    <ProductPlan
                                                      hosting={server}
                                                      disabled={
                                                        this.state.submitting
                                                      }
                                                      onAddToCart={
                                                        this.onAddToCart
                                                      }
                                                      disabled={
                                                        this.state.submitting &&
                                                        this.state
                                                          .submittingId ===
                                                          server.id
                                                      }
                                                      submitting={
                                                        this.state.submitting &&
                                                        this.state
                                                          .submittingId ===
                                                          server.id
                                                      }
                                                    />
                                                  </div>
                                                );
                                              })}
                                          </div>
                                        </TabContent>
                                      )}
                                    />
                                  </Switch>
                                );
                              })
                            }
                          />
                        )}
                      </div>
                    </ServersContentWithLoading>
                  )}
                </BrowserView>
              </div>
            </StyledServersWrapper>
          );
        }}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    servers: getServers(state),
    cartId: getCartId(state),
    cart: getCart(state),
    isFetchingServers: isFetchingServers(state),
    isAuthenticated: isAuthenticated(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchServers,
      addProductToCart,
      fetchCart
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSizes(isLaptop)(withLocalize(Servers)));
